<template>
 <div>
<!--   使用流程-线上选蛋糕-->
   <div v-if="stype == 0">
     <ul class="use_wrap">
       <li v-for="(item,index) in arr" :key="index">
         <img :src="item.bgurl" alt="">
         <p class="yuan"></p>
         <p class="jian">
           <img src="@/assets/img/right.png" alt="">
         </p>
         <p class="li_tit">{{item.name}}</p>
       </li>
     </ul>
     <div class="xian"></div>
   </div>
   <!--   使用流程-门店消费-->
   <div  v-if="stype == 1">
     <ul class="use_wrap">
       <li v-for="(item,index) in mdArr" :key="index">
         <img :src="item.bgurl" alt="">
         <p class="yuan"></p>
         <p class="jian">
           <img src="@/assets/img/right.png" alt="">
         </p>
         <p class="li_tit">{{item.name}}</p>
       </li>
     </ul>
     <div class="xian"></div>
   </div>

<!--   购票方式-->
   <div  v-if="stype == 2">
     <ul class="use_wrap">
       <li v-for="(item,index) in dyArr" :key="index">
         <img :src="item.bgurl" alt="">
         <p class="yuan"></p>
         <p class="jian">
           <img src="@/assets/img/right.png" alt="">
         </p>
         <p class="li_tit">{{item.name}}</p>
       </li>
     </ul>
     <div class="xian"></div>
   </div>
 </div>

</template>

<script>
export default {
  name: "pubUse",
  props:{
    stype:{
      type:Number,
      default:-1
    }
  },
  data() {
    return {
      arr:[{
        name:'蛋糕首页',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/sybg11.png'
      },{
        name:'蛋糕列表',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/sybg22.png'
      },{
        name:'选择规格',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/sybg33.png'
      },{
        name:'订单详情',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/sybg44.png'
      }],
      mdArr:[{
        name:'选择电子券',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/dgxf111.png'
      },{
        name:'订单确认',
        bgurl:'https://qiniu.youjiamall.cn/dgxf22.png'
      },{
        name:'查看门店消费码',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/dgxf333.png'
      },{
        name:'线下门店消费码',
        bgurl:'https://qiniu.youjiamall.cn/dgxf444.png'
      }],
      dyArr:[{
        name:'电影首页',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/dybg1.png'
      },{
        name:'选择座位',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/dybg2.png'
      },{
        name:'支付页面',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/dybg3.png'
      },{
        name:'订单详情',
        bgurl:'https://qiniu.youjiamall.cn/yjpc/dybg4.png'
      }],
    }
  }
}
</script>

<style scoped>
.use_wrap {
  margin: 0px 60px 22px;
  /*box-sizing: border-box;*/
  /*width: 100%;*/
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.use_wrap li{
  position: relative;
  margin-top: 41px;
  border-radius: 10px;
}
.use_wrap li:last-child .jian{
  display: none;
}
.yuan {
  width: 17px;
  height: 17px;
  background: #007858;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
}

.use_wrap li img {
  width: 198px;
  height: 403px;
}
.jian {
  position: absolute;
  bottom: -35px;
  right: -50px;
}
.use_wrap .jian img{
  width: 22px;
  height: 20px;
}
.li_tit {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom:-80px;
}
.xian {
  padding:  0 60px;
  box-sizing: border-box;
  width: 90%;
  height: 1px;
  margin: auto;
  background-image: linear-gradient(to right, #999999 35%, rgba(255,255,255,0) 0%); /* 35%设置虚线点x轴上的长度 */
  background-position: bottom; /* top配置上边框位置的虚线 */
  background-size: 10px 1px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
  background-repeat: repeat-x;
}
</style>
