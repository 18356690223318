<template>
    <div>

      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <img src="https://qiniu.youjiamall.cn/yjpc/banner6.png" alt="">
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
        <div>
            <pubBoxTit borderNone="1" title="蛋糕券" ltit="COMMON" paddingTop="30"></pubBoxTit>
            <holiCake></holiCake>
        </div>

      <div class="mai_wrap">
        <pubBoxTit  title="线下消费门店" ltit="shop" paddingTop="30"></pubBoxTit>
        <div class="mai_box">
          <holiBrand prodType="0"></holiBrand>
        </div>
      </div>

      <div class="mai_wrap">
        <pubBoxTit  title="线上消费品牌" ltit="brand" paddingTop="80"></pubBoxTit>
        <div class="mai_box">
          <holiBrand prodType="1"></holiBrand>
        </div>
      </div>

      <!-- 生日搭配惊喜-->
      <div class="sup_mai">
        <subTitle zh-tit="生日搭配惊喜" en-tit="BIRTHDAY WITH SURPRISE"></subTitle>
        <div class="sub_box">
          <cakeSub></cakeSub>
        </div>
      </div>
      <!--  使用流程   -->
      <div class="mai_wrap cake_wrap">
        <div class="cake_title">
          <pubBoxTit  title="使用流程" ltit="USAGE PROCESS" cakeTit="true" paddingTop="30"></pubBoxTit>
          <div class="tit_right_btn">
            <p  v-for="(item,index) in selArr" :class="{btnActive:current === index}" @mousemove="mouseChange(index)">{{item}}</p>
          </div>
        </div>
        <div class="mai_box">
          <pubUse v-show="current == 0" stype="0"></pubUse>
          <pubUse v-show="current == 1" stype="1"></pubUse>
        </div>
      </div>
      <pubFormInput></pubFormInput>
    </div>
</template>

<script>
import pubBoxTit from '@/components/pubBoxTit.vue'
import subTitle from '@/components/subTitle.vue'
import holiCake from '@/components/HoliComponents/holiCake'
import holiBrand from "@/components/HoliComponents/holiBrand";
import cakeSub from "@/components/cakeComponents/cakeSub";
import pubUse from "@/components/pubUse";
import pubFormInput from "@/components/pubFormInput";
export default {
  name:'Cake',
    components: {
        pubBoxTit,
        holiCake,
        holiBrand,
        cakeSub,
        subTitle,
        pubUse,
      pubFormInput
    },
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
    data() {
        return {
          current:0,
          selArr:["线上选蛋糕","门店消费"]
        };
    },

    methods: {
      mouseChange(index) {
        this.current = index;
      }
    }
}

</script>
<style scoped>
.mai_box {
  padding: 0 120px;
  box-sizing: border-box;
}
.cake_wrap {
  height: 684px;
  background: #E9EFF4;
}
.cake_title {
  position: relative;
}
.tit_right_btn {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}
.tit_right_btn p{
  cursor: pointer;
  width: 106px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
  margin-right: 10px;
}
.tit_right_btn .btnActive {
  background: #007858;
  color: #fff;
}


.sub_box {
  margin-top: 26px;
}
</style>
