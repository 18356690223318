<template>
  <div class="mai_content">
    <div v-if="prodType == 0">
      <p class="mai_title">甄选线下门店合作品牌，凭电子卡均可消费~( 更多品牌正在合作洽谈中，敬请期待！）</p>
      <ul class="top_ul">
        <li v-for="(item,index) in cardArr" @click="cardClick(index)" :class="{liActive:current == index}" :key="index">
          <img :src="item" alt="">
        </li>
        <li class="last_li">敬请期待......</li>
      </ul>
    </div>


    <div v-if="prodType == 1" class="mai_brand">
      <p class="mai_title" >已有600+甄选蛋糕品牌入驻平台，可通过线上选购，支持全国400+城市配送！</p>
      <ul>
        <li v-for="(item,index) in pinArr" @click="cardClick(index)" :class="{liActive:current == index}" :key="index">
            <img :src="item" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    prodType:{
      type:Number,
      default:-1
    }
  },
  name: "holiConven",
  data() {
    return {
      current:0,
      cardArr:[
          "https://qiniu.youjiamall.cn/yjpc/shop1.png",
          "https://qiniu.youjiamall.cn/yjpc/shop2.png",
          "https://qiniu.youjiamall.cn/yjpc/shop3.png",
          "https://qiniu.youjiamall.cn/yjpc/shop4.png",
          "https://qiniu.youjiamall.cn/yjpc/shop5.png",
          "https://qiniu.youjiamall.cn/yjpc/shop6.png",
          "https://qiniu.youjiamall.cn/yjpc/shop7.png",
          "https://qiniu.youjiamall.cn/yjpc/shop8.png",
          "https://qiniu.youjiamall.cn/yjpc/shop9.png",
          "https://qiniu.youjiamall.cn/yjpc/shop10.png",
          "https://qiniu.youjiamall.cn/yjpc/shop11.png",
          "https://qiniu.youjiamall.cn/yjpc/shop12.png",
          "https://qiniu.youjiamall.cn/yjpc/shop13.png",
          "https://qiniu.youjiamall.cn/yjpc/shop14.png",
          "https://qiniu.youjiamall.cn/yjpc/shop15.png",
          "https://qiniu.youjiamall.cn/yjpc/shop16.png",
          "https://qiniu.youjiamall.cn/yjpc/shop17.png",
          "https://qiniu.youjiamall.cn/yjpc/shop18.png",
      ],
      pinArr:["https://qiniu.youjiamall.cn/yjpc/pin_1%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_2%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_3%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_4%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_5%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_6%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_7%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_8%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_9%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_10%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_11%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_12%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_13%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_14%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_15%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_16%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_17%402x.png",
        "https://qiniu.youjiamall.cn/yjpc/pin_19%402x.png",

      ]
    }
  },
  methods:{
    cardClick(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped>
.mai_content ul {
  display: flex;
  flex-wrap: wrap;
}
.mai_content li {
  width: 212px;
  height: 106px;
  border: 1.2px solid #E9EFF4;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 20px;
  margin-bottom: 10px;
}
.mai_content .last_li {
  color: #007858;
  border: 1px solid #007858;
}
.top_ul img{
  transform: scale(0.8);
}

.mai_title {
  width: 100%;
  text-align: center;
  margin: 27px 0 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.mai_brand {
  width: 100%;
  box-sizing: border-box;
  padding: 0  0 100px 50px;
  }
.mai_brand .mai_title {
  margin: 60px 0pt;
}
.mai_brand ul {
  width: 100%;
}
.mai_brand img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.mai_brand li {
  width: 102px;
  height: 102px;
  margin-right: 85px;
  margin-bottom: 45px;
  border: none;
}

</style>
