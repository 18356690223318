<template>
  <div class="sup_title">
    <p>{{enTit}}</p>
    <span>{{zhTit}}</span>
  </div>
</template>

<script>
export default {
  props:{
    zhTit:{
      type:String,
      default:''
    },
    enTit:{
      type:String,
      default:''
    }
  },
  name: "subTitle"
}
</script>

<style scoped>
.sup_title {
  position: relative;
  width: 100%;
  height: 32px;
  text-align: center;
  z-index: 9;
}
.sup_title span {
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.sup_title p {
  font-size: 30px;
  font-family: Arial;
  font-weight: 400;
  color: #EEEEEE;
}
</style>
