<template>

  <div class="mai_wrap">
    <div class="mai_content">
      <div class="content_top">
        <div class="mai_card">
          <div class="card_box"></div>
          <div class="card_text">
            <span>生日福利</span>
            <p>线上选购蛋糕、鲜花支持全国配送，可选择品牌电子码，支持线上线下各大品牌，千余家门店消费。
              一年一岁生日都应该是一次惊喜，愿您的生日我们能带给您无穷的希望和快乐～</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "holiConven"
}
</script>

<style scoped>
.mai_wrap {
  background: url("https://qiniu.youjiamall.cn/yjpc/cake_bg01.png")no-repeat;
  background-size: 100% auto;
}
.mai_content {
  width: 100%;
  background: rgba(255,255,255,0.5);
  border-image: linear-gradient(-43deg, #FFFFFF, #FFFFFF, #FFFFFF) 2 2;
  padding: 35px 103px 35px;
  box-sizing: border-box;
}
.content_top {
  display: flex;
  justify-content: space-between;
}
.content_btm {
  margin-top: 45px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.content_btm span {
  display: inline-block;
  width: 338px;
  margin-top: 22px;
  font-size: 16px;
}
.mai_card {
  width: 100%;
  height: 392px;
  background: rgba(255,255,255,0.5);
  /*border: 2px solid;*/
  border-image: linear-gradient(-43deg, #FFFFFF, #FFFFFF, #FFFFFF) 2 2;
  box-shadow: 0px 14px 34px 0px rgba(0,0,0,0.14);
  border-radius: 9px;
}
.card_box {
  width: 441px;
  height: 220px;

  background: url("https://qiniu.youjiamall.cn/yjpc/cake_card.png")no-repeat;
  background-size: 100% 100%;
  margin: 32px auto 0;
}
.card_text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 624px;
  text-align: center;
  margin: 26px auto;
}
.card_text p {
  margin-top: 16px;
  line-height: 24px;
}
.card_text span {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
</style>
