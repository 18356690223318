<template>
  <div class="sub_wrap">
    <div class="sub_left"></div>
    <div class="sub_right">
      <ul>
        <li @mousemove="mouseChange(index)" v-for="(item,index) in subArr" :key="index" :class="{subActive:current == index}">
          <img v-if="current == index" :src="item.icon" alt="">
          <img v-else :src="item.selIcon" alt="">
          <p>{{item.name}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "cakeSub",
  data() {
    return {
      current:0,
      subArr:[{
        name:'精美蛋糕',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_1.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_1_1.png'
      },{
        name:'休闲娱乐',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_2.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_2_1.png'
      },{
        name:'图书世界',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_3.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_3_1.png'
      },{
        name:'鲜花/永生花',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_4.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_4_1.png'
      },{
        name:'浪漫礼物',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_5.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_5_1.png'
      },{
        name:'热播网剧',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_6.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_6_1.png'
      },{
        name:'精品水果',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_7.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_7_1.png'
      },{
        name:'个人护理',
        icon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_8.png',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/sup_icon_8_1.png'
      }]
    }
  },
  methods:{
    mouseChange(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped>
.sub_wrap {
  display: flex;
}
.sub_wrap img {
  /*width: 51px;*/
  /*height: 51px;*/
  /*transform: scale(3);*/
  margin-bottom: 31px;
}
.sub_left {
  width: 100%;
  height: 343px;
  background: url("https://qiniu.youjiamall.cn/yjpc/cake002.png")no-repeat center bottom;
  background-size: 100% auto;

}
.sub_right {
  background: #fff;
  width: 100%;
  height: 343px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.sub_right ul {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.sub_right li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 171px;
  height: 171px;
}
.sub_right img {
  transform: scale(1);
}
.subActive {
  background: #007858;
  color: #fff;
}
</style>
